import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import { JDPowerIncentive } from '@/lib/schema/incentives/types';
import { ArrowUpRightIcon } from '@heroicons/react/24/solid';
import React from 'react';

export interface IncentivesCardProps {
  incentive: JDPowerIncentive;
}

const IncentivesCard: React.FC<IncentivesCardProps> = ({ incentive }) => {
  return (
    <div className="flex flex-col items-start gap-3 self-stretch rounded border border-solid border-greyThinBorder p-5">
      <div className="flex flex-col gap-1">
        <Heading className="text-subHeadline3" level={HeadingLevels.H3}>
          {incentive.name}
        </Heading>
        <Heading className="text-body3 text-[#636363]" level={HeadingLevels.H3}>
          {incentive.grantor}
        </Heading>
      </div>

      <Heading className="text-subHeadline4" level={HeadingLevels.H3}>
        Up to ${(incentive.max_amount || 0).toLocaleString()}
      </Heading>

      <Heading
        level={HeadingLevels.H4}
        className="self-stretch text-body4 text-[#636363]"
      >
        {incentive.description}
      </Heading>

      <div className="flex w-full flex-row justify-start gap-0.5">
        <p className="text-subHeadline16px underline decoration-turquoise decoration-2 underline-offset-[5px]">
          {incentive.details_url && (
            <a
              href={incentive.details_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          )}
        </p>
        <ArrowUpRightIcon
          className="mt-1"
          stroke="#000000"
          height={16}
          width={16}
        />
      </div>
    </div>
  );
};

export default IncentivesCard;
